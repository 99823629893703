import axios from 'axios';
import { FETCH_RECIPES, FETCH_PAGES } from './types';

const API_BASE_URL = 'https://cdn.contentful.com';
const API_SPACE_ID = 'rzs6r9zlyji5';
const API_KEY = 'AZH2JCzbqSSDKA91T20BJw7fWgR0awV6TsQmqys9Gws';

/*recipes
https://cdn.contentful.com/spaces/rzs6r9zlyji5/entries?access_token=AZH2JCzbqSSDKA91T20BJw7fWgR0awV6TsQmqys9Gws&content_type=recipe
*/

export const fetchRecipes = () => async dispatch => {
    const res = await axios.get(`${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_KEY}&content_type=recipe`);
    dispatch({ type: FETCH_RECIPES, payload: res.data });
};

export const fetchPages = () => async dispatch => {
    const res = await axios.get(`${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_KEY}&content_type=page`);
    dispatch({ type: FETCH_PAGES, payload: res.data });
};