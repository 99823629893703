// rendering layer/view control (react router)
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';

import Header from './Header/';
import Footer from './Footer/';
import Home from './Home/';
import Recipes from './Recipes/';
import Recipe from './Recipe/';
import Page from './Page/';

class App extends Component {
    componentDidMount(){
        this.props.fetchRecipes();
    }

    render(){
        return (
            <BrowserRouter>   
                <Header />
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/recipes" component={Recipes} />
                    <Route  path="/recipes/:id" component={Recipe} />
                    <Route  path="/about" component={Page} />
                    <Route  path="/contact" component={Page} />
                </Switch>
                <Footer />
            </BrowserRouter>
        );
    }
}

export default connect(null, actions)(App);