import React, { Component } from 'react';
import { Link }  from 'react-router-dom';
import styles from './Footer.module.css'; 

class Footer extends Component {
    render(){
        return (
            <footer className={styles.mainFooter}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ul className={styles.mainFooter__links}>
                                <li className={styles.mainFooter__link}><Link to='/recipes'>Recipes</Link></li>
                                <li className={styles.mainFooter__link}><Link to='/about'>About</Link></li>
                                <li className={styles.mainFooter__link}><Link to='/contact'>Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <ul className={styles.mainFooter__termsLinks}>
                                <li className={styles.mainFooter__termsLink}><Link to='/terms'>Terms</Link></li>
                                <li className={styles.mainFooter__termsLink}><Link to='/privacy'>Privacy &amp; Cookie Policy</Link></li>
                            </ul>
                            <div className={styles.mainFooter__icons}>Icons made by <a href="https://www.flaticon.com/authors/darius-dan" rel="noopener noreferrer" title="Darius Dan">Darius Dan</a> from <a href="https://www.flaticon.com/"    rel="noopener noreferrer"         title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/"     rel="noopener noreferrer"        title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div>
                        </div>
                    </div>


                    
                </div>
                
            </footer>
                
        )
    }
}



export default Footer;