import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchRecipes } from '../../actions';

class AllRecipes extends Component {
    componentDidMount() {
        this.props.fetchRecipes();
    }

    renderRecipes() {
        if(this.props.recipes){
           let totalRecipes = 0;

           if(this.props.totalRecipes){
                totalRecipes = this.props.totalRecipes;
            } else{
                totalRecipes = 999999;

            }
          
            return this.props.recipes.items.slice(0, totalRecipes).map(recipe => {
                const recipeImageId = recipe.fields.image.sys.id;
                const recipeImageIndex = this.props.recipes.includes.Asset.findIndex(recipe => recipe.sys.id === recipeImageId);
                const recipeImage = this.props.recipes.includes.Asset[recipeImageIndex];

                return (
                    <div className="card" key={recipe.sys.id}>
                        <img src={`${recipeImage.fields.file.url}?w=800&h=400&fit=scale`} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title"><Link to={`/recipes/${recipe.fields.slug}`} className="stretched-link">{recipe.fields.title}</Link></h5>
                            <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        </div>
                    </div>
                );
            });
        } else {
            return (
                <div className="loading">Loading</div>
            );
        }
       
      }



    render(){
        return (
            <div className="card-deck">
                {this.renderRecipes()}
            </div>   
        )
    }
}

function mapStateToProps(state) {
    return { recipes: state.recipes };
}

export default connect(mapStateToProps, { fetchRecipes })(AllRecipes);
