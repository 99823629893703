import React, { Component } from 'react';
import AllRecipes from './AllRecipes';

class Recipes extends Component {

    render(){
        return (
            <section className="section-spacing">
                <div className="container">
                    <div className="row">
                        <div className="col-12"><h1>Recipes</h1></div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <AllRecipes/>     
                        </div>
                    </div>
                </div>    
            </section>  
        )
    }
}


export default Recipes;
