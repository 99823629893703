import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from "react-helmet";

import { fetchRecipes } from '../../actions';

class Recipe extends Component {
    componentDidMount() {
        this.props.fetchRecipes();
    }

    renderIngredients(recipe){
        return recipe.fields.ingredients.map(ingredient => {
            return (
                <li>{ingredient}</li>
            )
        })
    }



    renderRecipe() {
        if(this.props.recipes){
            const { match } = this.props
            const thisID = match.params.id;
            const recipeIndex = this.props.recipes.items.findIndex(recipe => recipe.fields.slug === thisID);
            const recipe = this.props.recipes.items[recipeIndex];
            
            const recipeImageId = recipe.fields.image.sys.id;
            const recipeImageIndex = this.props.recipes.includes.Asset.findIndex(recipe => recipe.sys.id === recipeImageId);
            const recipeImage = this.props.recipes.includes.Asset[recipeImageIndex];

           

            return (
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{recipe.fields.title} | BBQ Recipes</title>
                    </Helmet>
                    <h1>{recipe.fields.title}</h1>
                    <img src={`${recipeImage.fields.file.url}?w=200`} alt="" />
                    <ul className="ingredients">
                        {this.renderIngredients(recipe)}
                    </ul>
                </div>
            );
        } else {
            return (
                <div className="loading">Loading</div>
            );
        }
      }

    render(){
        return (
            <div>
                
                {this.renderRecipe()}
            </div>       
        )
    }
}

function mapStateToProps(state) {
    return { recipes: state.recipes };
}

export default connect(mapStateToProps, { fetchRecipes })(Recipe);
