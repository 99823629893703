import React, { Component } from 'react';
import { Link }  from 'react-router-dom';
import logo from '../assets/logo.png'; 
import styles from './Header.module.css'; 


class Header extends Component {
    render(){
        return (
           
            <header className={styles.mainHeader}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Link to='/'><img src={logo} className={styles.mainHeader__logo} alt="BBQ Recipes logo" /></Link>
                            <nav className={styles.nav}> 
                                <Link to='/recipes' className={styles.navLeft}>Recipes</Link>
                                <Link to='/about'  className={styles.navRight}>About</Link>
                            </nav>
                        </div>
                    </div>
                </div>
               
            </header>
                
        )
    }
}



export default Header;