import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import styles from './Home.module.css'; 
import AllRecipes from '../Recipes/AllRecipes';

class Home extends Component {
    render(){
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>BBQ Recipes</title>
                    <html class="stickyNav"></html>
                </Helmet>
                <section className={styles.headerImage}>

                </section>
                <section className="section-spacing">
                    <div className="container">
                        <div className="row">
                            <div className="col-12"><h1>Latest recipes</h1></div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <AllRecipes totalRecipes="3"/>     
                            </div>
                        </div>
                    </div>    
                </section>  
                
                
            </div>       
        )
    }
}

export default Home;