import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchPages } from '../../actions';

import '../assets/shared.css';
import styles from './Page.module.css'; 



class Page extends Component {
    componentDidMount() {
        this.props.fetchPages();
    }

    renderContent(thisPage){
        return thisPage.fields.content.content.map(block => {
            return (
                <p>{block.content[0].value}</p>
            )
        })
    }

    renderPage() {
        if(this.props.pages){
            const { match } = this.props
            const thisPath = match.path;
            const pagePath = thisPath.substring(1);

            const pageIndex = this.props.pages.items.findIndex(page => page.fields.slug === pagePath);
           const thisPage = this.props.pages.items[pageIndex];
          
            return (
                <div>
                    <h1>{thisPage.fields.title}</h1>
                    <div>
                        {this.renderContent(thisPage)}
                    </div>
                   
                </div>
            );
        } else {
            return (
                <div className="loading">Loading</div>
            );
        }
      }

    render(){
        return (
            <section className="section-spacing">
                <div className="container">

                    <div className="row">
                        <div className="col-12">
                        {this.renderPage()}
                        </div>
                    </div>
                </div>    
            </section>  
        )
    }
}

function mapStateToProps(state) {
    return { pages: state.pages };
}

export default connect(mapStateToProps, { fetchPages })(Page);
